import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import MainLogo from "../assets/image/mainLogo.jpg";
import { Autoplay } from "swiper";
import "swiper/css";

import axios from "../utils/axios";

const Footer = () => {
  const [footeraddress, setFooterAddress] = useState([]);
  const [subService, setSubservice] = useState([]);
  const [finnboutus, setFinaboutus] = useState();
  const [address, setAddress] = useState([]);
  useEffect(() => {
    axios
      .get("api/get_footer")
      .then((res) => {
        setFooterAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios.get("api/get_subservices").then((res) => {
      setSubservice(res.data.data);
    });
  }, []);

  useEffect(() => {
    axios
      .get("api/get_about_us")
      .then((res) => {
        setFinaboutus(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`api/get_addresses_by_country`)
      .then((res) => {
        setAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(footeraddress);
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-main-wrapper">
          <div className="footer-column">
            <div className="footer-header">
              <h2>COMPANY PROFILE</h2>
              <div className="py-2">
                <Link to="/" title="Global Consol">
                  <img src={MainLogo} alt="" />
                </Link>
              </div>

              <p
                dangerouslySetInnerHTML={{
                  __html: finnboutus
                    ? finnboutus.description.substring(0, 250)
                    : "",
                }}
              ></p>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-header">
              <h2>SERVICES</h2>
              <ul className="quick-linkss">
                {subService &&
                  subService.map((footerSub) => {
                    return (
                      <div>
                        <li>
                          <Link to={`service/${footerSub.id}`}>
                            {footerSub?.main_title}
                          </Link>
                        </li>
                      </div>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-header">
              <h2>CONTACT US</h2>
              <div className="contact-wrapper-address">
                <div>
                  <h4> Singapore</h4>

                  <ul>
                    <li>
                      <div>
                        <li>
                          <i className="fa fa-map-marker-alt"></i>
                          <p>
                            Blk 511 Kampong Bahru Road, #03-01 Keppel Distripark, Singapore 099447
                          </p>
                        </li>
                      </div>
                    </li>
                    <li>
                      <div>
                        <li

                        >
                          <i className="fas fa-mobile-alt"></i>
                          +65 69080838
                        </li>
                      </div>
                    </li>

                    <li>
                      <div>
                        <div>
                          <li

                          >
                            <i className="	fa fa-phone "></i>

                            <a>+65 96272211</a>
                          </li>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div>
                        <div>
                          <li

                          >
                            <i className="fa fa-envelope"></i>
                            buddhika@globalconsol.com
                          </li>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <Swiper
                  spaceBetween={0}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[Autoplay]}
                  slidesPerView={1}
                  breakpoints={{
                    0: {
                      width: 0,
                      slidesPerView: 1,
                    },
                    440: {
                      width: 768,
                      slidesPerView: 1,
                    },
                  }}
                >
                  {address &&
                    address.map((companyAddress) => {
                      return (
                        <>
                          <SwiperSlide>
                            <div>
                              <h4> {companyAddress.address_title}</h4>

                              <ul>
                                <li>
                                  {companyAddress?.address ? (
                                    <div>
                                      <li>
                                        <i className="fa fa-map-marker-alt"></i>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: companyAddress
                                              ? companyAddress.address
                                              : "",
                                          }}
                                        ></p>
                                      </li>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </li>
                                <li>
                                  {companyAddress?.phone ? (
                                    <div>
                                      <li
                                        onClick={() =>
                                          (window.location.href = `tel:${companyAddress.phone}`)
                                        }
                                      >
                                        <i className="fas fa-mobile-alt"></i>
                                        {companyAddress?.phone}
                                      </li>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </li>

                                <li>
                                  <div>
                                    {companyAddress?.telephone ? (
                                      <div>
                                        <li
                                          onClick={() =>
                                            (window.location.href = `tel:${companyAddress.telephone}`)
                                          }
                                        >
                                          <i className="	fa fa-phone "></i>

                                          <a
                                            dangerouslySetInnerHTML={{
                                              __html: companyAddress
                                                ? companyAddress.telephone
                                                : "",
                                            }}
                                          />
                                        </li>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>

                                <li>
                                  <div>
                                    {companyAddress?.email ? (
                                      <div>
                                        <li
                                          onClick={() =>
                                            (window.location.href = `mailto:${companyAddress.email}`)
                                          }
                                        >
                                          <i className="fa fa-envelope"></i>
                                          {companyAddress?.email}
                                        </li>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                </Swiper> */}
              </div>
            </div>
          </div>
        </div >
      </div >
      <div className="footer-bottom">
        <p>
          © 2024 Site Powered by Global Consolidators Pte Ltd,. All right
          reserved.
        </p>
      </div>
    </footer >
  );
};

export default Footer;
