import React, { useEffect, useState } from "react";
import Banner from "..//../Combonent/Banner";
import axios from "..//../utils/axios";
import { Link } from "react-router-dom";

const Career = () => {
  const [title] = useState("Career");
  const [careerdata, setCareerdata] = useState([]);

  useEffect(() => {
    axios.get("api/get_career").then((res) => {
      setCareerdata(res.data.data);
    });
  }, []);
  return (
    <div>
      <Banner mainPage="" subPage="" title={title} />

      <section className="our-services-inner">
        <div className="container">
          <div className="services-inner">
            <h6 className="wow fadeInLeftBig" data-wow-duration="2s">
              WORK WITH US
            </h6>
            <h2>Career</h2>
          </div>
          <div className="our-sv-wrapper-inner">
            {careerdata &&
              careerdata.map((careers, index) => {
                return (
                  <div key={index.id}>
                    <div className="our-sv-cards">
                      <i className="fas fa-code" aria-hidden="true"></i>
                      <div className="sv-content">
                        <h3>{careers?.main_title}</h3>
                        <div className="career-date">
                          <p>{careers?.closing_date}</p>
                        </div>

                        <Link to={`${careers.id}`}>Know Moreeeee</Link>
                      </div>
                      <div className="our-sv-cards-ovarlays"></div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Career;
