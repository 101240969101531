import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Banner from "../../Combonent/Banner";
import { imageUrl } from "../../utils/constants";

const AboutUs = () => {
  const [bannerContent, setBannerContent] = useState("");

  useEffect(() => {
    axios.get("api/get_banner").then((response) => {
      setBannerContent(response.data.data);
    });
  }, []);

  console.log(bannerContent);

  const [aboutUs, setAboutus] = useState([""]);

  useEffect(() => {
    axios.get("/api/get_about_us").then((response) => {
      // console.log(response.data.data);

      setAboutus(response.data.data[0]);
    });
  }, []);

  return (
    <div>
      <Banner mainPage="" subPage="" title={aboutUs.title} />
      <section className="inner-about inner-content">
        <div
          className="nz-section horizontal autoheight-true animate-false full-width-false "
          data-animation-speed="35000"
          data-parallax="false"
        >
          <div className="container">
            <div className="nz-row">
              <div
                className="col col-sm-12 col-lg-6 vc_col-md-12 col-xs-12 col-12  element-animate-false valign-top"
                data-effect="none"
                data-align="left"
              >
                <div className="col-inner">
                  <h2
                    style={{
                      fontSize: "30px",
                      color: "#333333",
                      lineHeight: "40px",
                      textAlign: "left",
                    }}
                    className="vc_custom_heading"
                  >
                    {aboutUs ? aboutUs.title : ""}
                  </h2>
                  <div
                    className="sep-wrap element-animate element-animate-false left nz-clearfix"
                    data-effect="none"
                  >
                    <div
                      className="nz-separator solid"
                      style={{
                        borderBottomColor: "#ffb700",
                        width: "100px",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      &nbsp;
                    </div>
                  </div>
                  <div
                    className="nz-column-text nz-clearfix  element-animate-false"
                    data-effect="none"
                    data-effect-speed="50"
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: aboutUs ? aboutUs.description : "",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="col col-sm-12 ol-lg-6 col-md-12 col-xs-12 col-12  element-animate-false valign-middle"
                data-effect="none"
                data-align="left"
              >
                <div className="col-inner" style={{}}>
                  <img
                    className="alignright"
                    src={`${imageUrl}images/${
                      aboutUs ? aboutUs.image_name : ""
                    }`}
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
