import React, { useEffect, useState } from "react";
import WeMakeImg from "..//../assets/image/services.jpg";
import axios from "..//../utils/axios";
import { Accordion } from "react-bootstrap";

const WhatMake = () => {
  const [makeUniq, setMakeUniq] = useState([""]);
  useEffect(() => {
    axios
      .get("api/get_unique")
      .then((res) => {
        setMakeUniq(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section className="home-accordion">
        <div className="row">
          <div className=" col-lg-4 homeImage-accordion">
            <div className="accordion-img">
              <img src={WeMakeImg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="ml-6">
              <div className="accordion-content">
                <h2 className="acc-main-head  vc_custom_heading">
                  WHAT MAKES US UNIQUE?
                </h2>
                <br />

                <div className="">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq
                              ? makeUniq.description_1_title
                              : "",
                          }}
                        ></p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq ? makeUniq.description_1 : "",
                          }}
                        ></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq
                              ? makeUniq.description_2_title
                              : "",
                          }}
                        ></p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq ? makeUniq.description_2 : "",
                          }}
                        ></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq
                              ? makeUniq.description_3_title
                              : "",
                          }}
                        ></p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq ? makeUniq.description_3 : "",
                          }}
                        ></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq
                              ? makeUniq.description_4_title
                              : "",
                          }}
                        ></p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeUniq ? makeUniq.description_4 : "",
                          }}
                        ></p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhatMake;
