import React, { useEffect, useState } from "react";

import axios from "../../utils/axios";
import { useParams } from "react-router-dom";
import WhereWeHeader from "../../Combonent/WhereWeHeader";
import Banner from "..//../Combonent/Banner";

const Detailswhereweare = () => {
  const [wheredetails, setWhereDetails] = useState([]);
  const { whereWeid } = useParams();

  useEffect(() => {
    axios
      .get("api/get_whereweare")
      .then((res) => {
        const whereFiltered = res.data.data.find(
          (value) => value.id === whereWeid
        );
        console.warn(whereFiltered);
        setWhereDetails(whereFiltered);
        // console.warn(whereFiltered);
      })
      .catch((error) => {
        return error;
      });
  }, [whereWeid]);

  console.warn(whereWeid);
  return (
    <>
      <section className="product_detailsection">
        <Banner mainPage="" subPage="" title="Where we are" />
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 col-12">
              <WhereWeHeader />
            </div>

            <div className="col-lg-8 col-12">
              <div className="product_text">
                <div className="inner_head">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: wheredetails ? wheredetails.main_title : "",
                    }}
                  ></h2>
                </div>
                <div className="product_dtails">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: wheredetails ? wheredetails.description : "",
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Detailswhereweare;
