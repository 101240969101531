import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { imageUrl } from "../../utils/constants";
import { Helmet } from 'react-helmet';
const HandleTitle = () => {

    const [tabtitle, setTabtitle] = useState("");


    useEffect(() => {
        axios.get("api/get_icons").then((res) => {
            setTabtitle(res.data.data[0])
        }).catch((err) => {
            console.log(err);
        })

     
   

       
    }, [])

    
    const tabTitlesb = tabtitle.title

    { document.title = tabTitlesb }




}

export default HandleTitle