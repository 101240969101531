import axios from "../../utils/axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const CareerDetails = () => {
  const [dataCareer, setDataCareer] = useState([]);
  const { careerData } = useParams();

  useEffect(() => {
    axios
      .get("api/get_career")
      .then((res) => {
        const dataFilter = res.data.data.find(
          (value) => value.id == careerData
        );
        setDataCareer(dataFilter);
        // console.warn(res.data.data);
      })
      .catch((error) => {
        return error;
      });
  }, [careerData]);
  console.warn("hellow", careerData);
  return (
    <div>
      <div class="career-inner">
        <div class="container">
          <h2>{dataCareer.main_title}</h2>
          <hr />

          <div class="career-location">
            <div class="col-lg-5" style={{ padding: "0" }}>
              <div class="row">
                <div class="col-lg-6 " style={{ padding: "0" }}>
                  <div class="location-id">
                    <div class="">
                      <i class="fa fa-map-marker-alt"></i>
                    </div>
                    <div class="">
                      <p>
                        <b>Location</b>
                      </p>
                      <p>{dataCareer?.location}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 " style={{ padding: "0" }}>
                  <div class="location-id">
                    <div class="">
                      <i class="fa fa-map-marker-alt"></i>
                    </div>
                    <div class="">
                      <p>
                        <b>Closing Date </b>
                      </p>
                      <p>{dataCareer?.closing_date}</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="career-role">
            <h5>ROLE OVERVIEW</h5>
            <hr />
            <p
              dangerouslySetInnerHTML={{
                __html: dataCareer ? dataCareer.description : "",
              }}
            ></p>
          </div>

          <div class="career-apply">
            <Link to="/career-form">Apply Now</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerDetails;
