import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import {  Link } from "react-router-dom";

const WhereWeHeader = () => {
  const [wheredetails, setWhereDetails] = useState([]);
  // const { whereWeid } = useParams();

  useEffect(() => {
    axios
      .get("api/get_whereweare")
      .then((res) => {
        // const whereFiltered = res.data.data.find(
        //   (value) => value.id == whereWeid
        // );

        setWhereDetails(res.data.data);
        // console.warn(whereFiltered);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  return (
    <div>
      <div className="col-lg-12 col-12">
        <div className="product_sidemenu">
          <ul>
            {wheredetails.map((data) => {
              return (
                <div key={data.id}>
                  <li className="dropdown-item">
                    <Link to={`/${data.id}`}>{data.main_title}</Link>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhereWeHeader;
