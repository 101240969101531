import React, { useEffect, useState } from "react";
import { imageUrl } from "../../utils/constants";
import axios from "../../utils/axios";

const Vissionvission = () => {

  const [vission, setVision] = useState([""]);
  const [mission, setMission] = useState([""]);

  useEffect(()=>{

    axios.get("api/get_vision").then((response)=>{

      setVision(response.data.data[0])

    }).catch((err)=>{
      console.log(err)
    })
    

    axios.get("api/get_mission").then((response)=>{
      setMission(response.data.data[0]);
    }).catch((err)=>{
      console.log(err)
    })

  },[])


  return (
    <div>
      <div className="mission-vission">
        <div className="">
          <div className="mission-vission-wrapper">
            <div className="mission-card">
              <div className="icon">
                <img
                   src={`${imageUrl}images/${
                      vission ? vission.icon_title : ""
                    }`}
                  className="img-fluid mission-icon"
                  alt=""
                />
              </div>
              <div className="content">
                <h2> {vission? vission.head:""}</h2>
             
                  <p
                      dangerouslySetInnerHTML={{
                        __html: vission ? vission.content: "",
                      }}
                    />
                
                 <div className="read--btn">
                   
                </div>
              </div>
            </div>
            <div className="vision-card">
             <div className="icon">
                <img
                   src={`${imageUrl}images/${
                      mission? mission.icon_title : ""
                    }`}
                  className="img-fluid mission-icon"
                  alt=""
                />
              </div>
              <div className="content">
                   <h2> {mission? mission.head:""}</h2>
               <p
                      dangerouslySetInnerHTML={{
                        __html: mission ?  mission.content: "",
                      }}
                    />
                <div className="read--btn">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vissionvission;
