import React from "react";

const callToform = () => {
  return (
    <div>
      <section class="blogss" id="scrolltoquote">
        <div
          class="nz-section horizontal autoheight-false animate-false full-width-false "
          data-animation-speed="35000"
          data-parallax="false"
          style={{ backgroundColor: "#ffffff", paddingTop: "60px" }}
        >
          <div class="container">
            <div class="nz-row">
              <div
                class="col vc_col-sm-12 col-12  element-animate-false valign-top"
                data-effect="none"
                data-align="left"
              >
                <div
                  class="nz-column-text nz-clearfix  element-animate-false ml-5"
                  data-effect="none"
                  data-effect-speed="50"
                >
                  <p></p>
                  <h3 class="widget-title">
                    <span class="widget-title__inline">
                      REQUEST A QUICK QUOTE
                    </span>
                  </h3>
                  <p></p>
                </div>
                <div
                  class="nz-column-text nz-clearfix  element-animate-false"
                  data-effect="none"
                  data-effect-speed="50"
                >
                  <p></p>
                  <p
                    style={{
                      fontSize: "16px;",
                      lineHeight: "26px",
                      color: "#777777",
                      textAlign: "left",
                    }}
                  >
                    Contact the Global Consolidators team if you’re interested
                    in knowing more about our consolidation services. Our team
                    will be in contact with you shortly to give you all the
                    information you require and that’s not all if you are our
                    customer and need to check the status of your shipment then
                    we have our shipment tracker just for you.
                  </p>
                  <p></p>
                </div>
                <div
                  role="form"
                  class="wpcf7"
                  id="wpcf7-f4283-p7626-o1"
                  lang="en-US"
                  dir="ltr"
                >
                  <div class="screen-reader-response"></div>
                  <form
                    action="/#wpcf7-f4283-p7626-o1"
                    method="post"
                    class="wpcf7-form"
                    novalidate="novalidate"
                  >
                    <div style={{ display: "none" }}>
                      <input type="hidden" name="_wpcf7" value="4283" />
                      <input
                        type="hidden"
                        name="_wpcf7_version"
                        value="5.0.3"
                      />
                      <input type="hidden" name="_wpcf7_locale" value="en_US" />
                      <input
                        type="hidden"
                        name="_wpcf7_unit_tag"
                        value="wpcf7-f4283-p7626-o1"
                      />
                      <input
                        type="hidden"
                        name="_wpcf7_container_post"
                        value="7626"
                      />
                    </div>
                    <div class="col col4" data-margin="true">
                      <span class="wpcf7-form-control-wrap your-name">
                        <input
                          type="text"
                          name="your-name"
                          value=""
                          size="40"
                          class="wpcf7-form-control wpcf7-text"
                          aria-invalid="false"
                          placeholder="Name"
                        />
                      </span>
                    </div>
                    <div class="col col4" data-margin="true">
                      <span class="wpcf7-form-control-wrap your-email">
                        <input
                          type="email"
                          name="your-email"
                          value=""
                          size="40"
                          class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                          aria-invalid="false"
                          placeholder="E-mail"
                        />
                      </span>
                    </div>
                    <div class="col col4" data-margin="true">
                      <span class="wpcf7-form-control-wrap your-subject">
                        <input
                          type="text"
                          name="your-subject"
                          value=""
                          size="40"
                          class="wpcf7-form-control wpcf7-text"
                          aria-invalid="false"
                          placeholder="Subject"
                        />
                      </span>
                    </div>
                    <div class="col col12" data-margin="true">
                      <span class="wpcf7-form-control-wrap your-message">
                        <textarea
                          name="your-message"
                          cols="40"
                          rows="10"
                          class="wpcf7-form-control wpcf7-textarea"
                          aria-invalid="false"
                          placeholder="Message"
                        ></textarea>
                      </span>
                    </div>
                    <div class="col col12">
                      <input
                        type="submit"
                        value="Send Message"
                        style={{ background: "#ffb700" }}
                        class="wpcf7-form-control wpcf7-submit"
                      />
                      <span class="ajax-loader" />
                      <span />
                    </div>
                    <div class="wpcf7-response-output wpcf7-display-none"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default callToform;
