import React, { useEffect, useState } from "react";

import "swiper/css";
import mainIcon from "../../assets/image/client1.png";
import axios from "..//..//utils/axios";
import { Link } from "react-router-dom";
import { imageUrl } from "../../utils/constants";
// import { Autoplay } from "swiper";

const Ourservice = () => {
  const [serviceCards, setServiceCard] = useState([]);

  useEffect(() => {
    axios
      .get("api/get_subservices")
      .then((res) => {
        setServiceCard(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <section className="servicesIndex" id="scrolltoservices">
        <div className="container">
          <div className="title-bar">
            <p>Our Service</p>
            <h2>
              Our <strong>Awesome</strong> Services{" "}
            </h2>
          </div>

          <div className="mainContainer">
            {serviceCards &&
              serviceCards.map((servicecards) => {
                return (
                  <>
                    <div className="mainServiceCard">
                      <div className="iconInsv">
                        <img
                          class="alignright  "
                          src={`${imageUrl}images/${
                            servicecards?.image_name_1 || mainIcon
                          }`}
                          alt="7584"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: servicecards ? servicecards.main_title : "",
                        }}
                      ></h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: servicecards
                            ? servicecards.description_1.substring(0, 130)
                            : "",
                        }}
                      ></p>

                      <p>{serviceCards.description_1}</p>
                      <div className="readMoreBtn">
                        <Link to={`service/${servicecards.id}`}>Read More</Link>
                      </div>
                      {/* <button>Read More</button> */}
                    </div>
                  </>
                );
              })}
            {/* <div className="mainServiceCard">
              <img src={mainIcon} />
              <h3>Ocean Freight</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </p>
              <butt>Read More</butt>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ourservice;
