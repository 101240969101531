import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import Banner from "./Banner";
import HomeStrip from "./HomeStrip";
import Ourservice from "./Ourservice";
import WhatMake from "./WhatMake";
import Homeform from "./Homeform";
import Aboutus from "./Aboutus";

import Vissionvission from "./Vissionvission";

const Index = () => {
  return (
    <>
      <div>
        <Banner />
        <HomeStrip />
        <Aboutus />
        <Ourservice />
        <Vissionvission />
        <Homeform />

        <WhatMake />
      </div>
    </>
  );
};

export default Index;
