import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "../src/assets/css/style.css";
import "../src/assets/css/mainstyle.css";
import "../src/assets/css/secondary.css";
import "../src/assets/css/new-css.css";
import "../src/assets/css/serv.css";
import "../src/assets/css/styles.less";
import ScrollToTop from "./Pages/Scrolltotop/Scrolltotop";

import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter scrollBehavior="smooth" basename="/">
    <ScrollToTop />
    <App />
  </BrowserRouter>
);
