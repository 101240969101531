import React, { useEffect, useState } from "react";

import axios from "..//../utils/axios";
import { imageUrl } from "../../utils/constants";
import { Link } from "react-router-dom";
import Banner from "..//../Combonent/Banner";
const Whereweare = () => {
  const [places, setPlaces] = useState([""]);

  useEffect(() => {
    axios.get("api/get_whereweare").then((res) => {
      setPlaces(res.data.data);
    });
  }, []);
  return (
    <div>
      <Banner mainPage="" subPage="" title="Where we are" />
      <section className="about-us inner-services">
        <div className="container">
          <div className="second-wrapper">
            {places &&
              places.map((mainplaces) => {
                return (
                  <>
                    {/* <div className="grid-item" key={mainplaces.id}>
                      <Link to={`/${mainplaces.id}`}>
                        <div className="block">
                          <div className="abt-second-content">
                            <h2>{mainplaces.main_title}</h2>
                          </div>
                          <img
                            src={`${imageUrl}images/${
                              mainplaces ? mainplaces.image_name : ""
                            }`}
                            alt=""
                          />
                        </div>
                      </Link>
                    </div> */}
                  </>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whereweare;
