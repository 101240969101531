// src/VideoPlayer.js
import React, { useRef, useEffect } from 'react';

const VideoPlayer = ({ src, isPlaying, onPlay }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <video
      ref={videoRef}
      src={src}
      controls
      onPlay={onPlay}
    />
  );
};

export default VideoPlayer;
