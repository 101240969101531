import React from "react";

const CareerForm = () => {
  return (
    <div>
      <div className="career-form">
        <div className="container">
          <form>
            <div className="row">
              <div className="form-group col-md-6">
                <label for="inputEmail4">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="First Name"
                />
              </div>
              <div className="form-group col-md-6">
                <label for="inputPassword4">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword4"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label for="inputEmail4">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="Email"
                />
              </div>
              <div className="form-group col-md-6">
                <label for="inputPassword4">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword4"
                  placeholder="Phone Number"
                />
              </div>
            </div>

            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input  "
                id="validatedCustomFile"
              />
              <label className="custom-file-label" for="validatedCustomFile">
                *Resume/ CV & Cover Letter
              </label>
            </div>

            <div className="career-apply">
              <a href="career-form">Apply Now</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
