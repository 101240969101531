import React, { useEffect, useState } from "react";
import axios from "..//..//utils/axios";
import AbtImage from "../../assets/image/about -us.jpeg";
import { Link } from "react-router-dom";
import { imageUrl } from "../../utils/constants";

const Aboutus = (props) => {
  const [Innboutus, setInnaboutus] = useState("");

  useEffect(() => {
    axios
      .get("api/get_about_us")
      .then((res) => {
        setInnaboutus(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.warn(Innboutus);

  return (
    <>
      <section className="HomeAbout">
        <div className="container">
          <div className="abt-row">
            <div className="aboutus-content">
              <div className="abt-title-strip">
                {<h1>{Innboutus.title}</h1>}
              </div>

              {/* <h2>About us</h2> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: Innboutus
                    ? Innboutus.description.substring(0, 500)
                    : "",
                }}
              />
              <div className="abt-read-more">
                <Link to="/aboutus">Read More</Link>
              </div>
            </div>

            <div className="abt-img">
              <img
                className="alignright"
                src={`${imageUrl}images/${
                  Innboutus ? Innboutus.image_name : ""
                }`}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
