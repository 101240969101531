import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <div>
      <section className="inner-banner">
        <header
          className="rich-header page-header version1"
          data-parallax="false"
        >
          <div className="container nz-clearfix">
            <div className="rh-content">
              <h1 style={{ color: "#777777" }}>{props.title}</h1>
              <div
                style={{ color: "#999999" }}
                className="nz-breadcrumbs nz-clearfix"
              >
                <Link to="/">Home</Link>

                {props.mainPage.length > 0 ? <Link>{props.mainPage}</Link> : ""}
                {props.subPage.length > 0 ? <Link>{props.subPage}</Link> : ""}

                <span>{props.title}</span>
              </div>
            </div>
          </div>
        </header>
      </section>
    </div>
  );
};

export default Banner;
